import dayjs, { addUtcPlugin, addRelativeTimePlugin } from "~/lib/dayjs";

// this function will return something like this
// 15 minutes ago
async function relativeTime(date: string | number) {
  if (dayjs(date).isValid()) {
    await addUtcPlugin();
    await addRelativeTimePlugin();
    return dayjs(date).utc().fromNow();
  }
  throw new Error("invalid date format");
}

function isBudhistYear() {
  return dayjs().year() > 2500;
}

interface GenerateTimesPayload {
  date: string;
  length?: number;
  startingTime?: string;
}

function generateTimes(payload?: GenerateTimesPayload) {
  const times = [];
  let currentHours = 0;
  let startingMinute = 0;
  const today = dayjs().format("YYYY-MM-DD");
  if (payload && payload.date === today) {
    if (payload && payload.startingTime) {
      const [hours, minutes] = payload.startingTime.split(":");
      currentHours = parseInt(hours);
      startingMinute = parseInt(minutes);
    } else {
      const currentTime = new Date();
      const currentMinutes = currentTime.getMinutes();
      const remainder = currentMinutes % 15;
      currentHours = currentTime.getHours();
      startingMinute =
        remainder === 0
          ? currentMinutes
          : currentMinutes + (15 - remainder) + 15;
    }
  } else if (payload && payload.startingTime) {
    const [hours, minutes] = payload.startingTime.split(":");
    currentHours = parseInt(hours);
    startingMinute = parseInt(minutes);
  }

  if (startingMinute === 60) {
    startingMinute = 0;
    currentHours++;
  } else if (startingMinute === 75) {
    startingMinute = 15;
    currentHours++;
  }

  for (let i = startingMinute; i <= 60; i += 15) {
    if (i === 60) {
      currentHours++;
      i = 0;
    }
    const hours = currentHours < 10 ? "0" + currentHours : currentHours;
    const minutes = i < 10 ? "0" + i : i;
    times.push(hours + ":" + minutes);
    if (currentHours === 24) {
      break;
    }
  }
  if (payload && payload.length) {
    return times.slice(0, payload.length);
  }
  return times;
}

interface GenerateDatesPayload {
  length?: number;
  startingDate?: string;
}

function generateDates(payload?: GenerateDatesPayload) {
  const dates = [];
  const datesLength = payload?.length || 7;
  let currentDate = new Date();
  if (payload && payload.startingDate) {
    currentDate = new Date(payload.startingDate);
  }
  for (let i = 0; i < datesLength; i++) {
    const date = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear();
    dates.push(`${year}-${month}-${date}`);
    currentDate.setDate(currentDate.getDate() + 1);
  }
  if (payload && payload.length) {
    return dates.slice(0, payload.length);
  }
  return dates;
}

export { relativeTime, isBudhistYear, generateTimes, generateDates };
